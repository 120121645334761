/* Estilos generales */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 40px;
    background-color: #324259;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo-container {
    display: flex;
    align-items: center;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
}

.button {
    background-color: #1abc9c;
    border: none;
    color: white;
    padding: 12px 25px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 16px;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
    height: 60px;
}

.link {
    text-decoration: none;
}

/* Estilos para el botón de hamburguesa */
.menu-button {
    display: none;
    font-size: 30px;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
}

/* Media queries para pantallas pequeñas */
@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        padding: 10px;
    }
    .nav-links {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        display: none; /* Escondido por defecto */
    }
    .nav-links.open {
        display: flex; /* Muestra el menú cuando tiene la clase "open" */
    }
    .button {
        padding: 10px 20px;
        font-size: 14px;
    }
    .logo {
        height: 50px;
    }
    .menu-button {
        display: block; /* Mostrar el botón de hamburguesa */
    }
}
